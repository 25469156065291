import React, { useState } from "react";
import "../assets/styles/Gallery.css";
import g1 from "../assets/images/gallery/g1.jpg";
import g2 from "../assets/images/gallery/g2.jpg";
import g3 from "../assets/images/gallery/g3.jpg";
import g4 from "../assets/images/gallery/g4.jpg";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { useParams } from "react-router-dom";
import Hospital from "../components/Hospital";
import HospitalStaff from "../components/HospitalStaff";

const Gallery = () => {

  const {data} = useParams()

 

  return (
    <>
      <section className="gallery_section_page">
        <div className="page-header-content">
          <div className="container">
            <div className="page-header-content-inner">
              <div
                className="page-title"
                style={{ textTransform: "capitalize" }}
              >
                <h2>Gallery</h2>
              </div>
              <ol className="breadcrumb">
                <li>
                  <a href="index.jsx">Home -</a>
                </li>
                <li className="active">Gallery</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* gallery */}

{data === "hospital" && <Hospital/>}

{data === "hospital-staff" && <HospitalStaff/>}
  
    </>
  );
};

export default Gallery;
