import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../assets/styles/treatmentDetail.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import teamA from "../assets/images/team/teamA.jpg";
import teamB from "../assets/images/team/teamB.jpg";
import teamC from "../assets/images/team/teamC.jpg";
import { SlArrowRight } from "react-icons/sl";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import fUser from "../assets/images/clients/fUser.jpg";
import sUser from "../assets/images/clients/sUser.jpg";
import tUser from "../assets/images/clients/tUser.jpg";
import eUser from "../assets/images/clients/eUser.jpg";
import { IoIosArrowDropdown } from "react-icons/io";
import axios from "axios";
import { SiLichess } from "react-icons/si";

const TreatmentDetail = () => {
  const { cate_slug, treat_slug } = useParams();
  const [stepImage, setStepImage] = useState("treat0");
  const [treatdesc, setTreatmentDesc] = useState("");
  const [treatmentImage, setTreamentImage] = useState();

  const [file, setFile] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [treatdetail, setTreatDetail] = useState({});
  const [treatmentSlug, setTreatmentSlug] = useState("");
  const [subspecialization, setSubspecialization] = useState([]);
  

  const handleTreatMent = (image, desc, active) => {
    setStepImage(active);
    setTreatmentDesc(desc);
    setTreamentImage(image);
  };

  useEffect(() => {
    let baseurl = process.env.REACT_APP_API_URL;
    axios
      .get(baseurl + `/v1/department-detail/` + treat_slug)
      .then((response) => {
        setTreatDetail(response.data?.data);
        console.log("Sub", response?.data);
        setSubspecialization(response?.data?.sub_specilization);
        if (response.data?.data?.treament[0]?.treat_title !== "") {
          setTreamentImage(response.data?.data?.treament[0]?.treat_image);
          setTreatmentDesc(response.data?.data?.treament[0]?.treat_desc);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [treat_slug]);

  // useEffect(() => {
  //   let baseurl = process.env.REACT_APP_API_URL;
  //   axios
  //     .get(baseurl + `/v1/department/` + slug)
  //     .then((response) => {
  //       setTreatmentSlug(response.data?.data)
  //       console.log(response.data?.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });

  //   axios
  //     .get(baseurl + `/v1/category/` + slug)
  //     .then((response) => {
  //       setCategory(response.data?.data);
  //       console.log(response.data?.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [treat_slug]);

  const changeImage = (data) => {
    setActiveIndex(data);
  };

  let url = window.location.href;
  let urldomain = url.split("/");

  return (
    <>
      <section className="page-header-section_treat service-title style-1">
        <div className="page-header-content">
          <div className="container">
            <div className="treatment_banner_wrapper">
              <div className="page-header-content-inner">
                <div
                  className="page-title"
                  style={{ textTransform: "capitalize" }}
                >
                  <h2>{treatdetail?.title}</h2>
                </div>
                <a href="#" className="lab-btn animation lg_size_btn">
                  <span>
                    Enquiry Now
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </span>
                </a>
              </div>
              <div className="treatment_intro">
                <img
                  src={treatdetail?.hero_image}
                  alt=""
                  className="banner_image"
                />
                <img
                  src={treatdetail?.hero_image}
                  alt=""
                  className="resp_image"
                />
              </div>
            </div>

            {/* <div className="treat_intro_list">
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                onSlideChange={() => console.log("slide change")}
                modules={[Autoplay, Pagination, Navigation]}
                onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                  <div class="feature-block">
                    <div class="title">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Pariatur, adipisci?
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div class="feature-block">
                    <div class="title">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Quos praesentium porro iure repudiandae quisquam dicta.
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div class="feature-block">
                    <div class="title">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Quos praesentium porro iure repudiandae quisquam dicta.
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div> */}
            <a href="#" className="lab-btn animation sm_size_btn">
              <span>
                Enquiry Now
                <i className="fa fa-angle-double-right" aria-hidden="true"></i>
              </span>
            </a>
          </div>
        </div>
      </section>

      {/* doctor detail */}
      <section className="treat_speciality padding-tb">
        <div className="container">
          <div className="doctor_speality_wrapper">
            <div className="spe_desp">
              <p>{treatdetail?.doctor_bio}</p>
            </div>
            <div className="spe_doc">
              <div className="spec_image">
                <img src={treatdetail?.doctor_image} alt="" />
              </div>
            </div>
            <div className="spe_intro">
              <div className="spec_doctor_det">
                <h2>{treatdetail?.doctor_name}</h2>
                <p className="digi">{treatdetail?.doctor_subtitle}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* our team */}
      <section className="speci_team padding-tb">
        <div className="container">
          <div
            className="section-header wow animate__animated animate__fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".1s"
          >
            <h2>{cate_slug} Team</h2>
          </div>

          <div>
            <Swiper
              spaceBetween={50}
              slidesPerView={3}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              onSlideChange={() => console.log("slide change")}
              modules={[Autoplay, Pagination, Navigation]}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {treatdetail?.team?.map((row, index) => (
                <SwiperSlide>
                  <img src={row?.team_image} alt="" style={{ width: "100%" }} />
                  <div className="spea_team_desc">{row?.team_desc}</div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>

      {/* treatemnts */}
      <section className="treatment_section padding-tb">
        <div className="container">
          <div
            className="section-header wow animate__animated animate__fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".1s"
          >
            <h2>Treatments</h2>
          </div>
          <div className="treaments_step_wrapper">
            <div className="treatment_lists">
              <ul className="treat_list_grp">
                {treatdetail?.treament?.map((row, index) => {
                  return (
                    <li
                      onClick={() =>
                        handleTreatMent(
                          row?.treat_image,
                          row?.treat_desc,
                          "treat" + index
                        )
                      }
                    >
                      <p
                        className={`${
                          stepImage === "treat" + index
                            ? "list-title active"
                            : "list-title"
                        }`}
                      >
                        {row?.treat_title}
                      </p>
                      <div>
                        {stepImage === "treat" + index ? (
                          <SlArrowRight color="#eb1d25" />
                        ) : (
                          <SlArrowRight />
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="treatment_image">
              <div className="treat_select_inner">
                <img src={treatmentImage} alt="" />
              </div>
            </div>
            <div className="treatment_desc">
              <p>{treatdesc}</p>
            </div>
          </div>

          {/* resp */}
          <div className="res_section_wrapper">
            <ul className="resp_lists">
              <li onClick={() => setStepImage(teamA)}>
                <p> World-class teamA</p>
                <div>
                  {stepImage === teamA ? (
                    <RiArrowDropUpLine size={"2em"} />
                  ) : (
                    <RiArrowDropDownLine size={"2em"} />
                  )}
                </div>
              </li>
              {stepImage === teamA && (
                <div className="resp_image_outer">
                  <div className="resp_image_inner">
                    <img src={stepImage} alt="" />
                  </div>
                </div>
              )}
              <li onClick={() => setStepImage(teamB)}>
                <p>Exceptional clinical teamB</p>
                <div>
                  {stepImage === teamB ? (
                    <RiArrowDropUpLine size={"2em"} />
                  ) : (
                    <RiArrowDropDownLine size={"2em"} />
                  )}
                </div>
              </li>
              {stepImage === teamB && (
                <div className="resp_image_outer">
                  <div className="resp_image_inner">
                    <img src={stepImage} alt="" />
                  </div>
                </div>
              )}
              <li onClick={() => setStepImage(teamC)}>
                <p> Latest high-end teamC</p>
                <div>
                  {stepImage === teamC ? (
                    <RiArrowDropUpLine size={"2em"} />
                  ) : (
                    <RiArrowDropDownLine size={"2em"} />
                  )}
                </div>
              </li>
              {stepImage === teamC && (
                <div className="resp_image_outer">
                  <div className="resp_image_inner">
                    <img src={stepImage} alt="" />
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
      </section>

      {/* technology */}
      <section className="tech_section padding-tb">
        <div className="container">
          <div
            className="section-header wow animate__animated animate__fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".1s"
          >
            <h2>{cate_slug} Technology</h2>
          </div>

          <div>
            <Swiper
              spaceBetween={50}
              slidesPerView={3}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              onSlideChange={() => console.log("slide change")}
              modules={[Autoplay, Pagination, Navigation]}
              onSwiper={(swiper) => console.log(swiper)}
            >

              {treatdetail?.technology?.map((row, index) => (
                <SwiperSlide>
                  <img
                    src={row?.tech_image}
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                  <div className="tech_sec">
                    <p>{row?.tech_desc}</p>
                    <IoIosArrowDropdown color="#eb1d25" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>

      {/* sub-specialization */}

      <section className="sub_treatment_special padding-tb">
        <div className="container">
          <div
            className="section-header wow animate__animated animate__fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".1s"
          >
            <h2>Know More About Our Sub-Specialization</h2>
          </div>

          <Swiper
            spaceBetween={50}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            onSlideChange={() => console.log("slide change")}
            modules={[Autoplay, Pagination, Navigation]}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {subspecialization && subspecialization?.map((treatment, index) => {
              return (
                <SwiperSlide>
                  <Link to={`/department/${urldomain[4]+'/treatment/'+treatment?.slug}`}>
                    <div
                      className="sub_dep_card"
                      onMouseEnter={() => changeImage(index)}
                      onMouseLeave={() => setActiveIndex(null)}
                    >
                      <img
                        src={
                          index === activeIndex
                            ? treatment?.special_hover_image
                            : treatment?.special_image
                        }
                        alt=""
                        style={{ width: "100%" }}
                      />

                      <div className="sub_dept_title">
                        <h4>{treatment?.title}</h4>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>

      {/* Patient Stories */}
      <section className="patient-stories padding-tb">
        <div className="container">
          <div
            className="section-header wow animate__animated animate__fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".1s"
          >
            <h2>Patient Stories</h2>
          </div>

          <div className="story_container_section">
            <div className="video-col-1">
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio1">
                    <img
                      src={fUser}
                      width="514"
                      height="400"
                      alt="mauritian patient treated for esophageal cancer"
                    />
                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt=""
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">
                      Mauritian Patient Treated For Esophageal Cancer
                    </div>
                    <p>Mr Fazl Hosany</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-col-2">
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={sUser}
                      width="514"
                      height="400"
                      alt="liver failure"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt="play-icon"
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Liver Failure</div>
                    <p>Baby Bhavika</p>
                  </div>
                </div>
              </div>
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={tUser}
                      alt="jaw cancer"
                      width="514"
                      height="400"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        width="48"
                        height="48"
                        alt="play-icon"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Jaw Cancer</div>
                    <p>Dr. Abhilasha Agarwal </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="video-col-2">
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={eUser}
                      width="514"
                      height="400"
                      alt="liver failure"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt="play-icon"
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Liver Failure</div>
                    <p>Baby Bhavika</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-col-2">
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={sUser}
                      width="514"
                      height="400"
                      alt="liver failure"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt="play-icon"
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Liver Failure</div>
                    <p>Baby Bhavika</p>
                  </div>
                </div>
              </div>
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={tUser}
                      alt="jaw cancer"
                      width="514"
                      height="400"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        width="48"
                        height="48"
                        alt="play-icon"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Jaw Cancer</div>
                    <p>Dr. Abhilasha Agarwal </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-col-1">
              {" "}
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio1">
                    <img
                      src={fUser}
                      width="514"
                      height="400"
                      alt="mauritian patient treated for esophageal cancer"
                    />
                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt=""
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">
                      Mauritian Patient Treated For Esophageal Cancer
                    </div>
                    <p>Mr Fazl Hosany</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="popup-media"
            style={{ display: file ? "block" : "none" }}
          >
            <span onClick={() => setFile(null)}>&times;</span>
            {file && (
              <iframe
                width="560"
                height="315"
                src={file}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TreatmentDetail;
