import React, { useState } from 'react'
import g5 from "../assets/images/gallery/g5.JPG";
import g6 from "../assets/images/gallery/g6.JPG";
import g7 from "../assets/images/gallery/g7.JPG";
import g8 from "../assets/images/gallery/g8.JPG";
import g9 from "../assets/images/gallery/g9.JPG";
import g10 from "../assets/images/gallery/g10.JPG";
import g11 from "../assets/images/gallery/g11.JPG";
import g12 from "../assets/images/gallery/g12.JPG";
import g13 from "../assets/images/gallery/g13.JPG";
import g14 from "../assets/images/gallery/g14.JPG";
import g15 from "../assets/images/gallery/g15.JPG";
import g16 from "../assets/images/gallery/g16.JPG";
import g17 from "../assets/images/gallery/g17.JPG";
import g18 from "../assets/images/gallery/g18.JPG";
import g19 from "../assets/images/gallery/g19.JPG";
import g20 from "../assets/images/gallery/g20.JPG";
import g21 from "../assets/images/gallery/g21.JPG";
import g22 from "../assets/images/gallery/g22.JPG";
import g23 from "../assets/images/gallery/g23.JPG";
import g24 from "../assets/images/gallery/g24.JPG";
import g25 from "../assets/images/gallery/g25.JPG";
import g26 from "../assets/images/gallery/g26.JPG";
import g27 from "../assets/images/gallery/g27.JPG";
import g28 from "../assets/images/gallery/g28.JPG";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";


const HospitalStaff = () => {

    const [sliderNumber, setSliderNumber] = useState(0);
    const [openModal, setOpenModal] = useState(false);
  
    const galleryImages = [
      {
        img: g5,
      },
      {
        img: g6,
      },
      {
        img: g7,
      },
      {
        img: g8,
      },
      {
        img: g9,
      },
      {
        img: g10,
      },
      {
        img: g11,
      },
      {
        img: g12,
      },
      {
        img: g13,
      },
      {
        img: g14,
      },
      {
        img: g15,
      },
      {
        img: g16,
      },
      {
        img: g17,
      },
      {
        img: g18,
      },
      {
        img: g19,
      },
      {
        img: g20,
      },
      {
        img: g21,
      },
      {
        img: g22,
      },
      {
        img: g23,
      },
      {
        img: g24,
      },
      {
        img: g25,
      },
      {
        img: g26,
      },
      {
        img: g27,
      },
      {
        img: g28,
      },
    ];
  
    const handleOpenModal = (data) => {
      setSliderNumber(data);
      setOpenModal(true);
    };
  
    const handleClosemodal = () => {
      setOpenModal(false);
    };
  
    const prevSlide = () => {
      sliderNumber === 0
        ? setSliderNumber(galleryImages.length - 1)
        : setSliderNumber(sliderNumber - 1);
    };
  
    const nextSlide = () => {
      sliderNumber + 1 === galleryImages.length
        ? setSliderNumber(0)
        : setSliderNumber(sliderNumber + 1);
    };
  return (
    <section className="galllery_md padding-tb">
    <div className="container">
      {openModal && (
        <div className="sliderWrap">
          <FaChevronCircleLeft
            className="btnPrev"
            onClick={prevSlide}
            size="40px"
          />

          <FaChevronCircleRight
            className="btnNext"
            onClick={nextSlide}
            size="40px"
          />
          <IoMdCloseCircle
            className="btnClose"
            onClick={handleClosemodal}
            size="40px"
          />

          <div className="fullScreenImage">
            <img src={galleryImages[sliderNumber].img} alt="" srcset="" />
          </div>
        </div>
      )}

      <div className="galleryWrap">
        {galleryImages &&
          galleryImages.map((slide, index) => {
            return (
              <div
                className="single"
                key={index}
                onClick={() => handleOpenModal(index)}
              >
                <img src={slide.img} alt="" />
              </div>
            );
          })}
      </div>
    </div>
  </section>
  )
}

export default HospitalStaff