import { Link } from "react-router-dom";
import "../assets/styles/main.css";
import "../assets/styles/responsive.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { FaBookMedical, FaPhone, FaWhatsapp } from "react-icons/fa6";
import { FaHamburger, FaTimes } from "react-icons/fa";
import { BiChat } from "react-icons/bi";

const Footer = () => {
  const [dropdownmenu, setDropdownMenu] = useState(null);
  const [data, setData] = useState({});
  const [firstHalf, setFirstHalf] = useState([]);
  const [secondHalf, setSecondHalf] = useState([]);
  const [callbackpoup, setCallBackpop] = useState(false)
  const toggleDropdown = (i) => {
    if (dropdownmenu === i) {
      return setDropdownMenu(null);
    }
    setDropdownMenu(i);
  };

  useEffect(() => {
    let baseurl = process.env.REACT_APP_API_URL;
    axios
      .get(baseurl + `/v1/category`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    console.log(data);
    const half = Math.ceil(data?.data?.length / 2);
    setFirstHalf(data?.data?.slice(0, half));
    setSecondHalf(data?.data?.slice(half));
  }, [data]);

  const handleSubmit = async(e) => {
    e.preventDefault();
    
    const formData = {
      name: e.target.elements.name.value,
      email: e.target.elements.email.value,
      phone: e.target.elements.phone.value
    };
    let baseurl = process.env.REACT_APP_API_URL;
    console.log('Form Data:', formData);
    const response  = await axios.post(baseurl + `/v1/enquirybackrequest`, formData);
    if(response?.data?.status===true){
      alert(response?.data?.message);
      e.target.reset();
    }
    // You can now send formData to your backend or handle it as needed
  };


  return (
    <>
      <div className={`footer-callback ${callbackpoup? '' : 'hidden'}`}>
        <div className="callback-body-content">
          <div className="callback-body">
            <span className="close" onClick={() => setCallBackpop(false)}><FaTimes /></span>
            <h1 className="mb-3">Call Back Request</h1>
            <form method="post" onSubmit={handleSubmit}> 
              <div className="form-group">
                <input type="text" name="name" className="form-control" placeholder="Name" required/>
              </div>
              <div className="form-group">
                <input type="text" name="email" className="form-control" placeholder="Email" required/>
              </div>
              <div className="form-group">
                <input type="text" name="phone" className="form-control" placeholder="phone" required/>
              </div>
              <div className="form-group">
                <button className="lab-btn animation" type="submit">Send</button>
              </div>
            </form>
            
          </div>
          
        </div>
      </div>
      <div className="foot-fixed">
        <div className="cm-flex justify-btw">
        <div className="cm-wd-25">
          <FaHamburger />
          <div>Menu</div>
        </div>
        <div className="cm-wd-25">
          <FaBookMedical />
          <div>Book</div>
        </div>
        <div className="cm-wd-25" >
          <BiChat  /> 
          <div>Chat</div>
        </div>
        <div className="cm-wd-25">
          <FaPhone />
          <div>Phone</div>
        </div>
        <div className="cm-wd-25 flex flex-wrap" onClick={() => setCallBackpop(true)}>
          <FaWhatsapp />
          <div>Call Back</div>
        </div>
        </div>
      </div>
      <section className="footer-section style-1">
        <div className="container">
          <div className="section-wrapper">
            <div className="footer-top">
              <div className="row">
                <div
                  className="footer_card animation"
                >
                  <div className="contact-info">
                    <h3>Contact Info</h3>
                    <p className="roboto-thin">
                      Rapidiously seize wireless strategic theme areas and
                      corporate testing procedures. Uniquely
                    </p>
                    <ul className="lab-ul">
                      <li>
                        <span style={{ marginRight: "5px" }}>
                          <i
                            className="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span>
                          {" "}
                          Patliputra Station,Infront of Pani Tanki Ashiyna
                          Nagar,Patna
                        </span>
                      </li>
                      <li>
                        <span style={{ marginRight: "5px" }}>
                          {" "}
                          <i
                            className="fa fa-phone"
                            aria-hidden="true"
                          ></i>{" "}
                        </span>
                        <span> +91-9006982665</span>
                      </li>
                      <li>
                        <span style={{ marginRight: "5px" }}>
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                        <span>
                          <span> info@aomc.com</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="footer_card animation"
                >
                  <div className="doctor-info mb-5 mb-sm-0">
                    <h3>Our Doctors</h3>
                    <ul className="lab-ul roboto-thin">
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="/doctor/dr-pravin-jha">DR PRAVIN KUMAR JHA</Link>
                      </li>
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link>DR AMITA BHARTI </Link>
                      </li>
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="#">DR SANJEEV PATHAK</Link>
                      </li>
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="#">DR SHASHANK OJHA</Link>
                      </li>
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="#">DR SHAILENDRA TIWARI</Link>
                      </li>
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="#">DR ABHINASH DUBE</Link>
                      </li>
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="#">DR RAGINI MISHRA</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="footer_card animation"
                >
                  <div className="service-info mb-5 mb-sm-0">
                    <h3>Our Services</h3>

                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <ul className="lab-ul roboto-thin">
                          {firstHalf?.map((row, index) => {
                            return (
                              <li key={index}>
                                <i
                                  className="fa fa-angle-double-right"
                                  aria-hidden="true"
                                ></i>
                                <Link to={`/department/${row.slug}`}>
                                  {row.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul className="lab-ul roboto-thin">
                          {secondHalf?.map((row, index) => (
                            <li>
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              <Link to={`/department/${row.slug}`}>
                                {row.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div
                className="copyright text-center animation"
              >
                <span>
                  Copyright &copy; 2024 <Link to="/">AOMC</Link>. Designed by
                  <Link to="https://csgroups.in/"> CS Groups</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
